
import { defineComponent } from "vue";
import { VoiceResponse } from "@/domain/pollster/model/voice/VoiceResponse";
import { environment } from "../../../../environment";

export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    new: String,
    loading: Boolean,
    voice: VoiceResponse,
    id: Number,
  },
  setup() {
    const baseUploadUrl = environment.baseUploadUrl;
    return {
      baseUploadUrl,
    };
  },
});

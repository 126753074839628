
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";
import { VueEditor, Quill } from "vue3-editor";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
    VueEditor,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const positionName = ref("");
    const positionDesc = ref("");
    const featureInput = ref("");
    const features = ref<string[]>([]);
    const router = useRouter();

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const pollResponse = ref<PollResponseListModel[]>([]);
    const pollChatLogs = ref<PollMessageListModel[]>([]);

    const getPoll = async () => {
      isLoading.value = true;

      const pollResponseModel: PollResponseListModel = {
        id: 146,
      };

      pollsterController
        .pollResponseList(pollResponseModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollResponse.value, response.getValue());
          } else {
            if (pollResponse.value.length == 0) {
              router.push({ name: "userPolls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getPollChatLogs = async () => {
      isLoading.value = true;

      const pollMessageListModel: PollMessageListModel = {
        poll: {
          id: 146,
        },
      };

      pollsterController
        .pollSessionMessages(pollMessageListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollChatLogs.value, response.getValue());
            console.log(pollChatLogs.value);
          } else {
            if (pollChatLogs.value.length == 0) {
              router.push({ name: "userPolls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case "OPEN_ENDED":
          return "Açık Uçlu";
        case "SINGLE_CHOICE":
          return "Tek Seçenekli";
        case "MULTIPLE_CHOICE":
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = ""; // Input alanını temizler
      }
    };

    const showCustomQuestionInput = ref(false);

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getPoll();
      getPollChatLogs();
    });

    return {
      questionTypeLabel,
      pollResponse,
      pollChatLogs,
      isLoading,
      positionName,
      featureInput,
      features,
      addFeature,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      showCustomQuestionInput,
      positionDesc,
    };
  },
});

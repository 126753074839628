<template>
  <div class="d-flex justify-content-start">
    <!--begin::Wrapper-->
    <div class="d-flex flex-column align-items-start">

      <!--begin::Text-->
      <div v-if="loading">
        <img class="h-35px" src="/media/modules/hotel/loading.gif"/>
      </div>
      <div v-else
        class="
          p-5
          rounded
          bg-primary
          text-light
          fw-bold
          text-start
        "
        data-kt-element="message-text"
      >
        {{ text }}
      </div>

      <!--end::Text-->
    </div>
    <a
      href="#"
      v-if="new"
      class="
        d-flex
        align-items-center
        text-gray-400 text-hover-primary
        me-5
        mb-2
        ms-3
        mt-12
      "
      ><span class="svg-icon svg-icon-4 me-1">
        <inline-svg src="/media/icons/duotune/social/soc011.svg" /></span
      >Beğenmedim</a
    >
  </div>
</template>

<script lang="ts">
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    new: String,
    loading: Boolean,
  },
  setup() {
    /*
    const store = useStore();
    const swalNotification = new SwalNotification();
    const sessionController = store.state.ControllersModule.sessionController;

    return {

    }
    */
  }
});
</script>

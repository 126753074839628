import { BaseResponseDataDto } from "@/domain/base/BaseResponseDataDto";
import { BaseResponseDto } from "@/domain/base/BaseResponseDto";
import { VoiceResponseAttribute } from "./VoiceResponseAttribute";
import { BaseDTO } from "@/core/base/BaseDTO";

export class VoiceResponse
  extends BaseDTO
  implements BaseResponseDto<VoiceResponseAttribute>
{
  data: BaseResponseDataDto<VoiceResponseAttribute>;

  constructor(data: any) {
    super();
    this.data = data;
  }
}

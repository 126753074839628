
import { defineComponent } from "vue";
import VueApexCharts from "vue3-apexcharts";
import { VoiceResponse } from "@/domain/pollster/model/voice/VoiceResponse";
import { environment } from "../../../../environment";

export default defineComponent({
  name: "message-out",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    voice: Object,
    video: Object,
    emotionAnalysis: Object,
    id: Number,
  },
  setup() {
    const baseUploadUrl = environment.baseUploadUrl;

    const chartOptions = {
      chart: {
        width: 700,
        type: "polarArea",
      },
      labels: ["A", "B", "C", "D", "E"],
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 1,
        colors: undefined,
      },
      yaxis: {
        show: false,
      },
      legend: {
        position: "bottom",
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
          spokes: {
            strokeWidth: 0,
          },
        },
      },
      theme: {
        monochrome: {
          enabled: true,
          shadeTo: "light",
          shadeIntensity: 0.6,
        },
      },
    };

    const series = [42, 47, 52, 58, 65];

    return {
      chartOptions,
      series,
      baseUploadUrl,
    };
  },
});

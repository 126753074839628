<template>
  <div class="row g-7">
    <!--begin::Contact groups-->
    <div class="col-lg-6 col-xl-3">
      <div class="card card-flush mb-7">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>Hybrid Çalışacak Frontend Developer</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::Contact group-->
          <div class="d-flex flex-stack">
            <a class="fs-6 fw-bold text-gray-800 text-hover-primary"
              >Pozisyon:
            </a>
            <div class="badge badge-light-info d-inline">
              Frontend Developer
            </div>
          </div>
          <a class="fs-6 fw-bold text-gray-800 text-hover-primary mt-3"
            >Tanım:
          </a>
          <p>
            Yaratıcı ve dinamik bir yazılım ekibine katılacak, kullanıcı dostu
            web uygulamaları geliştirmek için güçlü bir frontend developer
            arıyoruz. Çalışma modeli hybrid olacak, ofis ve uzaktan çalışma
            fırsatı sunulmaktadır.
          </p>
          <a class="fs-6 fw-bold text-gray-800 mt-3">Teklif Edilen İmkanlar</a>
          <ul>
            <li>Hybrid çalışma modeli (ofis ve uzaktan çalışma seçeneği)</li>
            <li>Esnek çalışma saatleri</li>
            <li>Yaratıcı ve destekleyici bir çalışma ortamı</li>
            <li>
              Yeteneklerinizi geliştirmek için eğitim ve gelişim fırsatları
            </li>
          </ul>

          <!--begin::Contact group-->
          <!--begin::Permissions-->
          <a class="fs-6 fw-bold text-gray-800 text-hover-primary mt-3"
            >Gereksinimler:
          </a>
          <div class="d-flex flex-column text-gray-600">
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Minimum 3 yıl iş
              tecrübesi
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> İlgili mühendislik
              bölümlerinden mezun
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Vuejs, Typescript ile
              tecrübesi olan
            </div>

            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span>
              <em>9 adet daha..</em>
            </div>
          </div>
          <!--end::Permissions-->
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <div class="card-footer pt-0">
          <button
            type="button"
            class="btn btn-light btn-active-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_update_role"
          >
            Pozisyonu Düzenle
          </button>
        </div>
        <!--end::Card footer-->
      </div>
      <!--begin::Modal-->
      <div
        class="modal fade"
        id="kt_modal_update_role"
        tabindex="-1"
        aria-hidden="true"
      >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-750px">
          <!--begin::Modal content-->
          <div class="modal-content">
            <!--begin::Modal header-->
            <div class="modal-header">
              <!--begin::Modal title-->
              <h2 class="fw-bold">Pozisyonu Güncelle</h2>
              <!--end::Modal title-->

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-roles-modal-action="close"
              >
                <i class="ki-duotone ki-cross fs-1"
                  ><span class="path1"></span><span class="path2"></span
                ></i>
              </div>
              <!--end::Close-->
            </div>
            <!--end::Modal header-->

            <!--begin::Modal body-->
            <div class="modal-body scroll-y mx-5 my-7">
              <!--begin::Form-->
              <form
                id="kt_modal_update_role_form"
                class="form fv-plugins-bootstrap5 fv-plugins-framework"
                action="#"
              >
                <!--begin::Scroll-->
                <div
                  class="d-flex flex-column scroll-y me-n7 pe-7"
                  id="kt_modal_update_role_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_update_role_header"
                  data-kt-scroll-wrappers="#kt_modal_update_role_scroll"
                  data-kt-scroll-offset="300px"
                  style="max-height: 541px"
                >
                  <!--begin::Input group-->
                  <div class="fv-row mb-10 fv-plugins-icon-container">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2">
                      <span class="required">Pozisyon Adı</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      class="form-control form-control-solid"
                      placeholder="Pozisyon adı girin"
                      name="role_name"
                      value="Frontend Developer"
                    />
                    <!--end::Input-->
                    <div
                      class="
                        fv-plugins-message-container
                        fv-plugins-message-container--enabled
                        invalid-feedback
                      "
                    ></div>
                  </div>
                  <!--end::Input group-->

                  <div class="fv-row mb-5">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2"
                      >İlan Açıklaması</label
                    >
                    <!--end::Label-->

                    <vue-editor
                      v-model="positionDesc"
                      class="form-control form-control-solid"
                      style="padding: 0px"
                    ></vue-editor>
                  </div>

                  <!--begin::Permissions-->
                  <div class="fv-row mb-5">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2"
                      >Aranılan Özellikler</label
                    >
                    <!--end::Label-->

                    <!--begin::Input group-->
                    <div class="mb-6 fv-row">
                      <!--begin::Input-->
                      <input
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        v-model="featureInput"
                        @keyup.enter="addFeature"
                        placeholder="Özellik ekleyin ve Enter'a basın"
                        v-on:keydown.enter.prevent
                      />
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->

                    <!--begin::Feature List-->
                    <div v-if="features.length" class="card-body pt-0">
                      <div class="d-flex flex-column text-gray-600">
                        <div
                          v-for="(feature, index) in features"
                          :key="index"
                          class="d-flex align-items-center py-2"
                        >
                          <span class="bullet bg-primary me-3"></span>
                          {{ feature }}
                        </div>
                      </div>
                    </div>
                    <!--end::Feature List-->
                  </div>
                  <!--end::Permissions-->

                  <div class="mb-0 fv-row">
                    <label
                      class="
                        d-flex
                        align-items-center
                        fs-5
                        fw-bold
                        form-label
                        mb-5
                      "
                    >
                      Mülakat Türü Seç
                      <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        title="Mülakat türünü özelleştirip mülakatlarınızı gerçekleştirebilirsiniz."
                      ></i>
                    </label>

                    <div class="mb-0">
                      <!-- Özel Mülakat -->
                      <label class="d-flex flex-stack mb-5 cursor-pointer">
                        <span class="d-flex align-items-center me-2">
                          <span class="symbol symbol-50px me-6">
                            <span class="symbol-label">
                              <span
                                class="svg-icon svg-icon-1 svg-icon-gray-600"
                              >
                                <inline-svg
                                  src="media/icons/duotune/finance/fin001.svg"
                                />
                              </span>
                            </span>
                          </span>
                          <span class="d-flex flex-column">
                            <span
                              class="
                                fw-bolder
                                text-gray-800 text-hover-primary
                                fs-5
                              "
                              >Özel Mülakat</span
                            >
                            <span class="fs-6 fw-bold text-gray-400"
                              >Tamamen özelleştirilmiş sorular ile mülakat
                              oluşturun.</span
                            >
                          </span>
                        </span>
                        <span
                          class="form-check form-check-custom form-check-solid"
                        >
                          <Field
                            class="form-check-input"
                            type="radio"
                            name="accountPlan"
                            value="1"
                            @change="showCustomQuestionInput = true"
                          />
                        </span>
                      </label>

                      <!-- Star Metodolojisi -->
                      <label class="d-flex flex-stack mb-5 cursor-pointer">
                        <span class="d-flex align-items-center me-2">
                          <span class="symbol symbol-50px me-6">
                            <span class="symbol-label">
                              <span
                                class="svg-icon svg-icon-1 svg-icon-gray-600"
                              >
                                <inline-svg
                                  src="media/icons/duotune/graphs/gra006.svg"
                                />
                              </span>
                            </span>
                          </span>
                          <span class="d-flex flex-column">
                            <span
                              class="
                                fw-bolder
                                text-gray-800 text-hover-primary
                                fs-5
                              "
                              >Star Metodolojisi</span
                            >
                            <span class="fs-6 fw-bold text-gray-400"
                              >Star metodolojisi ile adayınızdan kendini
                              tanıtmasını ve mesleğe bakış açısını anlatmasını
                              isteyin</span
                            >
                          </span>
                        </span>
                        <span
                          class="form-check form-check-custom form-check-solid"
                        >
                          <Field
                            class="form-check-input"
                            type="radio"
                            name="accountPlan"
                            value="2"
                            @change="showCustomQuestionInput = false"
                          />
                        </span>
                      </label>
                    </div>
                  </div>

                  <div class="w-100">
                    <!-- Sorular Alanı -->
                    <div class="mb-5" v-if="showCustomQuestionInput">
                      <label
                        class="
                          d-flex
                          align-items-center
                          fs-5
                          fw-bold
                          form-label
                        "
                        >Sorular</label
                      >
                      <div class="d-flex align-items-center mb-3">
                        <!-- Soru Yazma Inputu -->
                        <input
                          type="text"
                          v-model="newQuestion.text"
                          @keyup.enter="addQuestion"
                          class="
                            form-control form-control-lg form-control-solid
                            me-3
                          "
                          placeholder="Sorunuzu buraya yazın"
                          v-on:keydown.enter.prevent
                        />

                        <!-- Soru Tipi Seçme -->
                        <select
                          v-model="newQuestion.type"
                          class="form-select form-select-lg me-3"
                          aria-label="Soru Türü"
                        >
                          <option value="open">Açık Uçlu</option>
                          <option value="single">Tek Seçenekli</option>
                          <option value="multiple">Çok Seçenekli</option>
                        </select>

                        <!-- Yeni Soru Ekle Butonu -->
                        <button
                          type="button"
                          @click="addQuestion"
                          class="btn btn-primary"
                        >
                          Soru Ekle
                        </button>
                      </div>

                      <!-- Eğer Tek veya Çok Seçenekli ise, Opsiyon Ekleme Alanı Göster -->
                      <div
                        v-if="
                          newQuestion.type === 'single' ||
                          newQuestion.type === 'multiple'
                        "
                        class="mb-3"
                      >
                        <input
                          type="text"
                          v-model="newOptionText"
                          @keyup.enter="addOption"
                          class="form-control form-control-sm me-3"
                          placeholder="Opsiyon eklemek için yazın ve Enter'a basın"
                          v-on:keydown.enter.prevent
                        />
                        <!-- Eklenen Opsiyonları Gösterme -->
                        <ul class="list-group mt-2">
                          <li
                            v-for="(option, index) in newQuestion.options"
                            :key="index"
                            class="
                              list-group-item
                              d-flex
                              justify-content-between
                              align-items-center
                            "
                          >
                            {{ option }}
                            <button
                              type="button"
                              @click="removeOption(index)"
                              class="btn btn-sm btn-danger"
                            >
                              Sil
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- Eklenen Soruların Listesi -->
                    <div
                      v-if="questions.length > 0 && showCustomQuestionInput"
                      class="mt-5"
                    >
                      <h3
                        class="
                          d-flex
                          align-items-center
                          fs-5
                          fw-bold
                          form-label
                          mb-4
                        "
                      >
                        Eklenen Sorular
                      </h3>
                      <ul class="list-group">
                        <li
                          v-for="(question, index) in questions"
                          :key="index"
                          class="
                            list-group-item
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div>
                            <strong>Soru:</strong> {{ question.text }}<br />
                            <strong>Tür:</strong>
                            {{
                              question.type === "open"
                                ? "Açık Uçlu"
                                : question.type === "single"
                                ? "Tek Seçenekli"
                                : "Çok Seçenekli"
                            }}
                            <ul
                              v-if="
                                question.options && question.options.length > 0
                              "
                              class="mt-2"
                            >
                              <li
                                v-for="(option, i) in question.options"
                                :key="i"
                              >
                                {{ option }}
                              </li>
                            </ul>
                          </div>
                          <button
                            @click="removeQuestion(index)"
                            class="btn btn-sm btn-danger"
                          >
                            Sil
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!--end::Scroll-->

                <!--begin::Actions-->
                <div class="text-center pt-15">
                  <button
                    type="reset"
                    class="btn btn-light me-3"
                    data-kt-roles-modal-action="cancel"
                  >
                    Vazgeç
                  </button>

                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-kt-roles-modal-action="submit"
                  >
                    <span class="indicator-label"> Güncelle </span>
                    <span class="indicator-progress">
                      Lütfen bekleyiniz...
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                  </button>
                </div>
                <!--end::Actions-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Modal body-->
          </div>
          <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
      </div>
      <!--end::Modal-->

      <!--begin::Contact group wrapper-->
      <div class="card card-flush">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>Mülakat Sayısı</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Contact groups-->
          <div class="d-flex flex-column gap-5">
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                href="/metronic8/demo1/apps/contacts/getting-started.html"
                class="
                  fs-6
                  fw-bold
                  text-gray-800 text-hover-primary text-active-primary
                  active
                "
                >Toplam Mülakat</a
              >
              <div class="badge badge-light-primary">11</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                href="/metronic8/demo1/apps/contacts/getting-started.html"
                class="fs-6 fw-bold text-gray-800 text-hover-primary"
                >Tamamlanan</a
              >
              <div class="badge badge-light-primary">5</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                href="/metronic8/demo1/apps/contacts/getting-started.html"
                class="fs-6 fw-bold text-gray-800 text-hover-primary"
                >Devam Eden</a
              >
              <div class="badge badge-light-primary">4</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                href="/metronic8/demo1/apps/contacts/getting-started.html"
                class="fs-6 fw-bold text-danger text-hover-primary"
                >İptal Olan</a
              >
              <div class="badge badge-light-danger">2</div>
            </div>
            <!--begin::Contact group-->
          </div>
          <!--end::Contact groups-->
          <!--begin::Separator-->
          <div class="separator my-7"></div>
          <!--begin::Separator-->

          <!--begin::Add new contact-->
          <router-link to="/interview/invite-candidate">
            <a class="btn btn-primary w-100">
              <i class="ki-duotone ki-badge fs-2"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
                ><span class="path5"></span
              ></i>
              Mülakat Daveti Gönder
            </a>
          </router-link>
          <!--end::Add new contact-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contact group wrapper-->
    </div>
    <!--end::Contact groups-->

    <!--begin::Search-->
    <div class="col-lg-6 col-xl-3">
      <!--begin::Contacts-->
      <div class="card card-flush" id="kt_contacts_list">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_contacts_list_header">
          <!--begin::Form-->
          <form
            class="d-flex align-items-center position-relative w-100 m-0"
            autocomplete="off"
          >
            <!--begin::Icon-->
            <i
              class="
                ki-duotone ki-magnifier
                fs-3
                text-gray-500
                position-absolute
                top-50
                ms-5
                translate-middle-y
              "
              ><span class="path1"></span><span class="path2"></span
            ></i>
            <!--end::Icon-->

            <!--begin::Input-->
            <input
              type="text"
              class="form-control form-control-solid ps-13"
              name="search"
              value=""
              placeholder="Aday Ara"
            />
            <!--end::Input-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5" id="kt_contacts_list_body">
          <!--begin::List-->
          <div
            class="scroll-y me-n5 pe-5 h-300px h-xl-auto"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_contacts_list_header"
            data-kt-scroll-wrappers="#kt_content, #kt_contacts_list_body"
            data-kt-scroll-stretch="#kt_contacts_list, #kt_contacts_main"
            data-kt-scroll-offset="5px"
            style="max-height: 953px"
          >
            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >B</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Buse Çallı</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    busecalli25@gmail.com
                  </div>
                </div>

                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >M</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Melodi Cansın</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    melody@altbox.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-primary
                      text-primary
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >M</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Mesut Gürses</a
                  >
                  <div class="fw-semibold fs-7 text-muted">max@kt.com</div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-primary
                      text-primary
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >S</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Sacit Gürsoy</a
                  >
                  <div class="fw-semibold fs-7 text-muted">sean@ogza.com</div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >B</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Beril Sergün</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    beril@sergun.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >R</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Rüveyda Açıkalın</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    ruveyda@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >T</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Fatma Turgut</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    f.turgut@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-primary
                      text-primary
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >T</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Tuğberk Gürsoy</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    tugberk@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >N</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Nalan Pekgür</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    nalan.pekgur@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >D</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Dalia Taşdemir</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    daliatas@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div class="d-flex flex-stack py-4 contact-item">
              <!--begin::Details-->
              <div class="d-flex align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >M</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Merve Gürses</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    mervegurs@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->
            </div>
            <!--end::User-->
          </div>
          <!--end::List-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contacts-->
    </div>
    <!--end::Search-->

    <!--begin::Content-->
    <div class="col-xl-6">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <!--begin:::Tabs-->
        <ul
          class="
            nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x
            border-0
            fs-4
            fw-semibold
            mb-8
          "
          role="tablist"
        >
          <!--begin:::Tab item-->
          <li class="nav-item" role="presentation">
            <a
              class="nav-link text-active-primary pb-4 active"
              data-bs-toggle="tab"
              href="#kt_customer_view_overview_tab"
              aria-selected="true"
              role="tab"
              >Aday Detayları</a
            >
          </li>
          <!--end:::Tab item-->

          <!--begin:::Tab item-->
          <li class="nav-item" role="presentation">
            <a
              class="nav-link text-active-primary pb-4"
              data-bs-toggle="tab"
              href="#kt_customer_view_overview_events_and_logs_tab"
              aria-selected="false"
              role="tab"
              tabindex="-1"
              >Kullanıcı Cevapları</a
            >
          </li>
          <!--end:::Tab item-->

          <!--begin:::Tab item-->
          <li class="nav-item" role="presentation">
            <a
              class="nav-link text-active-primary pb-4"
              data-kt-countup-tabs="true"
              data-bs-toggle="tab"
              href="#kt_customer_view_overview_statements"
              data-kt-initialized="1"
              aria-selected="false"
              role="tab"
              tabindex="-1"
              >Mülakat Logları</a
            >
          </li>
          <!--end:::Tab item-->
        </ul>
        <!--end:::Tabs-->

        <!--begin:::Tab content-->
        <div class="tab-content" id="myTabContent">
          <!--begin:::Tab pane-->
          <div
            class="tab-pane fade active show"
            id="kt_customer_view_overview_tab"
            role="tabpanel"
          >
            <!--begin::Contacts-->
            <div class="card card-flush h-lg-100" id="kt_contacts_main">
              <!--begin::Card header-->
              <div class="card-header pt-3" id="kt_chat_contacts_header">
                <!--begin::Card title-->
                <div class="card-title">
                  <i class="ki-duotone ki-badge fs-1"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span><span class="path4"></span
                    ><span class="path5"></span
                  ></i>
                  <h2>Aday Detayları</h2>
                </div>
                <div class="card-toolbar gap-3">
                  <span class="badge badge-light-success"
                    >Mülakat tamamlandı</span
                  >

                  <div
                    class="
                      menu
                      menu-sub
                      menu-sub-dropdown
                      menu-column
                      menu-rounded
                      menu-gray-600
                      menu-state-bg-light-primary
                      fw-semibold
                      fs-7
                      w-125px
                      py-4
                    "
                    data-kt-menu="true"
                  >
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                      <a
                        href="/metronic8/demo1/apps/contacts/edit-contact.html"
                        class="menu-link px-3"
                      >
                        Edit
                      </a>
                    </div>
                    <!--end::Menu item-->

                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class="menu-link px-3"
                        id="kt_contact_delete"
                        data-kt-redirect="/metronic8/demo1/apps/contacts/getting-started.html"
                      >
                        Delete
                      </a>
                    </div>
                    <!--end::Menu item-->
                  </div>
                  <!--end::Menu-->
                  <!--end::Action menu-->
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->

              <!--begin::Card body-->
              <div class="card-body pt-5">
                <!--begin::Profile-->
                <div class="d-flex gap-7 align-items-center">
                  <div class="symbol symbol-100px symbol-circle mb-2">
                    <!--begin:: Avatar -->
                    <div
                      class="
                        symbol symbol-circle symbol-50px
                        overflow-hidden
                        me-1
                      "
                    >
                      <a
                        href="/metronic8/demo1/apps/user-management/users/view.html"
                        ><div
                          class="
                            symbol-label
                            fs-1
                            bg-light-primary
                            text-primary
                          "
                        >
                          B
                        </div></a
                      >
                    </div>
                    <!--end::Avatar-->
                  </div>

                  <!--begin::Contact details-->
                  <div class="d-flex flex-column gap-2">
                    <!--begin::Name-->
                    <h2 class="mb-0">Buse Çallı</h2>
                    <!--end::Name-->

                    <!--begin::Email-->
                    <div class="d-flex">
                      <i class="ki-duotone ki-sms fs-2"
                        ><span class="path1"></span><span class="path2"></span
                      ></i>
                      <a href="#" class="text-muted text-hover-primary"
                        >busecalli25@gmail.com</a
                      >
                    </div>
                    <!--end::Email-->

                    <!--begin::Phone-->
                    <div class="d-flex">
                      <i class="ki-duotone ki-phone fs-2"
                        ><span class="path1"></span><span class="path2"></span
                      ></i>
                      <a href="#" class="text-muted text-hover-primary"
                        >+905359807641</a
                      >
                    </div>
                    <!--end::Phone-->
                  </div>
                  <div class="d-flex flex-wrap flex-center">
                    <!--begin::Stats-->
                    <div
                      class="
                        border border-gray-300 border-dashed
                        rounded
                        py-3
                        px-3
                        mb-3
                      "
                    >
                      <div class="fs-4 fw-bold text-gray-700">
                        <span class="w-75px">9/10</span>
                        <i class="ki-duotone ki-arrow-up fs-3 text-success"
                          ><span class="path1"></span><span class="path2"></span
                        ></i>
                      </div>
                      <div class="fw-semibold text-muted">Beceri</div>
                    </div>
                    <!--end::Stats-->

                    <!--begin::Stats-->
                    <div
                      class="
                        border border-gray-300 border-dashed
                        rounded
                        py-3
                        px-3
                        mx-4
                        mb-3
                      "
                    >
                      <div class="fs-4 fw-bold text-gray-700">
                        <span class="w-50px">10/10</span>
                        <i class="ki-duotone ki-arrow-down fs-3 text-danger"
                          ><span class="path1"></span><span class="path2"></span
                        ></i>
                      </div>
                      <div class="fw-semibold text-muted">Heves</div>
                    </div>
                    <!--end::Stats-->

                    <!--begin::Stats-->
                    <div
                      class="
                        border border-gray-300 border-dashed
                        rounded
                        py-3
                        px-3
                        mb-3
                      "
                    >
                      <div class="fs-4 fw-bold text-gray-700">
                        <span class="w-50px">9/10</span>
                        <i class="ki-duotone ki-arrow-up fs-3 text-success"
                          ><span class="path1"></span><span class="path2"></span
                        ></i>
                      </div>
                      <div class="fw-semibold text-muted">Yetenek</div>
                    </div>
                    <!--end::Stats-->
                  </div>
                  <!--end::Contact details-->
                </div>

                <!--end::Profile-->

                <div class="d-flex flex-column gap-5 mt-7">
                  <!--begin::Company name-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">Mezun Olduğu Bölüm</div>
                    <div class="fw-bold fs-6">Yazılım Mühendisliği</div>
                  </div>
                  <!--end::Company name-->
                  <!--begin::Company name-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">Mezun Olduğu Okul</div>
                    <div class="fw-bold fs-6">Doğuş Üniversitesi</div>
                  </div>
                  <!--end::Company name-->

                  <!--begin::City-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">İş Tecrübesi</div>
                    <div class="fw-bold fs-6">2 yıl</div>
                  </div>
                  <!--end::City-->

                  <!--begin::Country-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">Yabancı Dil</div>
                    <div class="fw-bold fs-6">İngilizce</div>
                  </div>
                  <!--end::Country-->

                  <!--begin::Notes-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">Adres</div>
                    <div class="fw-bold fs-6">
                      Cevatpaşa Mahallesi Çalışkan Sokak No 14 Daire 7,
                      Istanbul, Turkiye
                    </div>
                  </div>
                  <!--end::Notes-->
                </div>
                <!--end::Additional details-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Contacts-->
            <!--begin::Earnings-->
            <div class="card mb-4 mb-xl-9 mt-7">
              <!--begin::Header-->
              <div class="card-header border-0">
                <div class="card-title">
                  <h2>Mülakat Analizi</h2>
                </div>
              </div>
              <!--end::Header-->

              <!--begin::Body-->
              <div class="card-body py-0">
                <div class="fs-5 fw-semibold text-gray-500 mb-4">
                  Adayın mülakat deneyimi ile yapay zekanın analizi ve ilgili
                  pozisyona uygunluk oranı
                </div>

                <!--begin::Left Section-->
                <div class="d-flex flex-wrap flex-stack mb-5">
                  <!--begin::Row-->
                  <div class="d-flex flex-wrap">
                    <!--begin::Col-->
                    <div
                      class="
                        border border-dashed border-gray-300
                        w-150px
                        rounded
                        my-3
                        p-4
                        me-6
                      "
                    >
                      <span class="fs-1 fw-bold text-gray-800 lh-1">
                        <span
                          data-kt-countup="true"
                          data-kt-countup-value="6,840"
                          data-kt-countup-prefix="$"
                          class="counted"
                          data-kt-initialized="1"
                          >93%</span
                        >
                        <i class="ki-duotone ki-arrow-up fs-1 text-success"
                          ><span class="path1"></span><span class="path2"></span
                        ></i>
                      </span>
                      <span
                        class="fs-6 fw-semibold text-muted d-block lh-1 pt-2"
                        >Pozisyon Uygunluğu</span
                      >
                    </div>
                    <!--end::Col-->

                    <!--begin::Col-->
                    <div
                      class="
                        border border-dashed border-gray-300
                        w-125px
                        rounded
                        my-3
                        p-4
                        me-6
                      "
                    >
                      <span class="fs-1 fw-bold text-gray-800 lh-1">
                        <span
                          class="counted"
                          data-kt-countup="true"
                          data-kt-countup-value="16"
                          data-kt-initialized="1"
                          >79</span
                        >%
                        <i class="ki-duotone ki-arrow-down fs-1 text-danger"
                          ><span class="path1"></span><span class="path2"></span
                        ></i>
                      </span>
                      <span
                        class="fs-6 fw-semibold text-muted d-block lh-1 pt-2"
                        >CV Analizi</span
                      >
                    </div>
                    <!--end::Col-->

                    <!--begin::Col-->
                    <div
                      class="
                        border border-dashed border-gray-300
                        w-150px
                        rounded
                        my-3
                        p-4
                        me-6
                      "
                    >
                      <span class="fs-1 fw-bold text-gray-800 lh-1">
                        <span
                          data-kt-countup="true"
                          data-kt-countup-value="1,240"
                          data-kt-countup-prefix="$"
                          class="counted"
                          data-kt-initialized="1"
                          >90%</span
                        >
                      </span>
                      <span
                        class="fs-6 fw-semibold text-muted d-block lh-1 pt-2"
                        >Duygu Analizi</span
                      >
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Row-->
                </div>
                <!--end::Left Section-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Earnings-->
          </div>
          <!--end:::Tab pane-->

          <!--begin:::Tab pane-->
          <div
            class="tab-pane fade"
            id="kt_customer_view_overview_events_and_logs_tab"
            role="tabpanel"
          >
            <div class="card card-flush h-lg-100" id="kt_contacts_main">
              <!--begin::Card header-->
              <div class="card-header" id="kt_chat_contacts_header">
                <!--begin::Card title-->
                <div class="card-title pt-3">
                  <i class="ki-duotone ki-badge fs-1"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span><span class="path4"></span
                    ><span class="path5"></span
                  ></i>
                  <h2>Kullanıcı Cevapları</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->

              <!--begin::Card body-->
              <div
                class="card-body pt-5 mb-7"
                style="max-height: 700px; overflow: auto"
              >
                <div class="d-flex flex-column gap-5">
                  <!--begin::Company name-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold fs-6 text-muted">
                      Uygulamamızı nasıl buldunuz?
                    </div>
                    <div class="fw-bold fs-5">
                      Uygulama güzel, kullanışlı, tasarımı basit.
                    </div>
                  </div>
                  <!--end::Company name-->
                  <!--begin::Company name-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold fs-6 text-muted">
                      Uygulamamızı puanlar mısınız?
                    </div>
                    <div class="fw-bold fs-5">4</div>
                  </div>
                  <!--end::Company name-->

                  <!--begin::City-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold fs-6 text-muted">
                      Uygulamamızı arkadaşlarınıza tavsiye eder misiniz?
                    </div>
                    <div class="fw-bold fs-5">Ederim</div>
                  </div>
                  <!--end::City-->
                </div>
                <!-- <div
                  class="row mt-5"
                  v-for="(item, index) in pollResponse"
                  :key="index"
                >
                  <div class="col-md-6 d-flex justify-content-end" v-if="item">
                    <ResponseIn
                      ref="messagesInRef"
                      :text="item.questionText"
                    ></ResponseIn>
                  </div>
                  <div class="col-md-6 d-flex justify-content-start">
                    <ResponseOut
                      v-if="item.surveyQuestionResponse"
                      ref="messagesOutRef"
                      :text="item.surveyQuestionResponse"
                    ></ResponseOut>
                    <ResponseOut
                      v-else
                      ref="messagesOutRef"
                      text=""
                    ></ResponseOut>
                  </div>
                </div> -->
              </div>
              <!--end::Card body-->
            </div>
          </div>
          <!--end:::Tab pane-->

          <!--begin:::Tab pane-->
          <div
            class="tab-pane fade"
            id="kt_customer_view_overview_statements"
            role="tabpanel"
          >
            <!--begin::Statements-->
            <div class="card mb-6 mb-xl-9">
              <!--begin::Header-->
              <div class="card-header">
                <!--begin::Title-->
                <div class="card-title pt-3">
                  <h2>Mülakat Logları</h2>
                </div>
                <!--end::Title-->
              </div>
              <!--end::Header-->

              <!--begin::Card body-->
              <div
                class="card-body pb-5 mb-7"
                style="max-height: 700px; overflow: auto"
              >
                <div
                  class="row mt-5"
                  v-for="(log, index) in pollChatLogs"
                  :key="index"
                >
                  <MessageIn
                    v-if="log.poll.owner == 'AI'"
                    ref="messagesInRef"
                    :text="log.poll.text"
                    :id="log.poll.id"
                    :voice="log.poll.voice"
                  ></MessageIn>
                  <MessageOut
                    v-else
                    ref="messagesOutRef"
                    :text="log.poll.text"
                    :voice="log.poll.voice"
                    :video="log.poll.video"
                    :id="log.poll.id"
                  ></MessageOut>
                </div>
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Statements-->
          </div>
          <!--end:::Tab pane-->
        </div>
        <!--end:::Tab content-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";
import { VueEditor, Quill } from "vue3-editor";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
    VueEditor,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const positionName = ref("");
    const positionDesc = ref("");
    const featureInput = ref("");
    const features = ref<string[]>([]);
    const router = useRouter();

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const pollResponse = ref<PollResponseListModel[]>([]);
    const pollChatLogs = ref<PollMessageListModel[]>([]);

    const getPoll = async () => {
      isLoading.value = true;

      const pollResponseModel: PollResponseListModel = {
        id: 146,
      };

      pollsterController
        .pollResponseList(pollResponseModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollResponse.value, response.getValue());
          } else {
            if (pollResponse.value.length == 0) {
              router.push({ name: "userPolls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getPollChatLogs = async () => {
      isLoading.value = true;

      const pollMessageListModel: PollMessageListModel = {
        poll: {
          id: 146,
        },
      };

      pollsterController
        .pollSessionMessages(pollMessageListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollChatLogs.value, response.getValue());
            console.log(pollChatLogs.value);
          } else {
            if (pollChatLogs.value.length == 0) {
              router.push({ name: "userPolls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case "OPEN_ENDED":
          return "Açık Uçlu";
        case "SINGLE_CHOICE":
          return "Tek Seçenekli";
        case "MULTIPLE_CHOICE":
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = ""; // Input alanını temizler
      }
    };

    const showCustomQuestionInput = ref(false);

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getPoll();
      getPollChatLogs();
    });

    return {
      questionTypeLabel,
      pollResponse,
      pollChatLogs,
      isLoading,
      positionName,
      featureInput,
      features,
      addFeature,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      showCustomQuestionInput,
      positionDesc,
    };
  },
});
</script>

<style>
.contact-item:hover {
  background-color: #f0f0f0; /* Gri renk */
  cursor: pointer; /* Tıklanabilir işaretçi */
}

/* İsteğe bağlı olarak hover'da font rengini değiştirme */
.contact-item:hover .fs-6 {
  color: #007bff; /* Örneğin, mavi renk */
}
</style>
